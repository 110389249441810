<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row class="mt-3 mb-4 mx-0">
            <v-col cols="9" class="align-self-center pa-0" align="start">
              <h2 class="font-weight-bold text-h4 basil--text">Cursos publicados</h2>
            </v-col>
            <v-col cols="3" class="align-self-center pa-0" align="right">
              <v-btn
                color="primary"
                @click="$router.push({ path: '/elearning/createcourse' })"
              >
                Nuevo Curso
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <course-card v-bind="courseData" v-if="courseData" />
        </v-col>
        <v-col cols="4">
          <course-card v-bind="courseData2" v-if="courseData2" />
        </v-col>
        <v-col cols="4">
          <course-card v-bind="courseData3" v-if="courseData3" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <course-card v-bind="courseData4" v-if="courseData4" />
        </v-col>
        <v-col cols="4" />
        <v-col cols="4" />
      </v-row>
    </v-container>
  </div>
</template>
<script>

  import CourseCard from '@/components/Elearning/CourseCard'
  export default {
    components: {
      CourseCard,
    },
    data() {
      return {
        courseData: {
          thumbnail:
            'https://investguideturkey.com/wp-content/uploads/2019/02/Franchising-Jan-2018-1.jpg',
          subtitle: '',
          href: '/elearning/coursedetail',
          title: 'Introduccion a la franquicias',
          name: 'Carlos Nunez',
          info: '25 Usuarios',
          active: 1,
          progress: 50,
        },
        courseData2: {
          thumbnail:
            'https://www.definicion.co/wp-content/uploads/2015/07/Conocimiento.jpg',
          subtitle: '',
          href: '/elearning/coursedetail',
          title: 'Conocimientos de Interés Universal',
          name: 'Equipo de Malllikeu',
          info: '67 Usuarios',
          progress: 15,
          active: 1,
        },
        courseData3: {
          thumbnail:
            'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/71ed939120a4b5e1b2d6de3a5240a43e.png',
          subtitle: '',
          href: '/elearning/coursedetail',
          title: 'Gestión de Vendedores',
          name: 'Equipo de Malllikeu',
          info: '5 Usuarios',
          progress: 0,
          active: 1,
        },
        courseData4: {
          thumbnail:
            'https://www.revistanuve.com/wp-content/uploads/2020/04/covid-19-pandemic.jpg',
          subtitle: '',
          href: '/elearning/coursedetail',
          title: 'Propiedad Intelectual',
          name: 'Equipo de Malllikeu',
          info: '0 Usuarios',
          progress: 0,
          active: 0,
        },
      }
    },
    beforeDestroy() {
      this.courseData = null
      delete this.courseData
    },
  }
</script>
